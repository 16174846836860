import axios from "./axios.js"

export class MailLogs {

    static getMailLogs() {
        return axios.get("/maillogs");

    }
    static getMailLog(id) {
        let defaultURL = `/maillogs/${id}`;
        return axios.get(defaultURL);
    }
    static createMailLogs(mailLogs) {
        let defaultURL = `/maillogs`;
        return axios.post(defaultURL, mailLogs);
    }
    static updateMailLogs(mailLogs, id) {
        let defaultURL = `/maillogs/${id}`;
        return axios.put(defaultURL, mailLogs);
    }
    static deleteMailLogs(id) {
        let defaultURL = `/maillogs/${id}`;
        return axios.delete(defaultURL);
    }

}