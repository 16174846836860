<template>


    <div class="container">
        <hr />
        <div class="table-responsive">
            <table class="table" ref="mailLogTable">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Mail Id</th>
                        <th>Mail Subject</th>
                        <th>Mail Content</th>
                        <th>Mail Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="maillog in mailLogs" :key="maillog.id">
                        <td>{{ maillog.id }}</td>
                        <td>{{ maillog.receiptsList }}</td>
                        <td>{{ maillog.mailSubjcet }}</td>
                        <td>{{ maillog.mailContent }}</td>
                        <td>{{ maillog.mailStatus }}</td>
                        <td><button class="btn btn-danger btn-sm" @click="deletedata(maillog.id)"><i class="fa fa-trash" aria-hidden="true"></i> Delete</button> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="container" v-if="loading">
        <div class="row">
            <div class="col">
                <Spinner />
            </div>
        </div>
    </div>
</template>

<script>


import { MailLogs } from '../services/MailLogs'

import Spinner from '../components/Spinner.vue';
import { useUserStore } from '../stores/userstore';
import $ from "jquery";
import dt from "datatables.net";
$(function () {
    //  alert('ok');
    
});
export default {
    name: 'MailLogs',
    components: {
        Spinner
    },
    setup() {
        const store = useUserStore();

        return {
            store
        }
    },
    data() {
        return {
            form: {
                id: '',

            },
            dt: dt,
            loading: false,
            mailLogs: [],
            errors: []
        }
    },
    async created() {

        try {
            this.loading = true;
            let response = await MailLogs.getMailLogs();
            //   console.log(response.data);
            this.mailLogs = response.data;
            this.loading = false
        }
        catch (error) {
            this.errors = error;
            this.loading = false;
        }

    },
    mounted() {
        this.dt = $(this.$refs.mailLogTable).DataTable();
    },
    watch: {
        mailLogs() {
            this.dt.destroy();
            this.$nextTick(() => {
                this.dt = $(this.$refs.mailLogTable).DataTable()
            })
        }
    }, methods: {
        async deletedata(id) {
            let result = await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            // alert(result.isConfirmed);
            if (result.isConfirmed) {
                this.loading = true;
                const resDel = await MailLogs.deleteMailLogs(id)
                // console.log(resDel);
                if (resDel.status == 204) {
                    this.$swal(
                        'MailLog Deleted',
                        resDel.data.status,
                        'success'
                    );
                }

            }

            // alert(id);

            // this.loading = true;
            let response = await MailLogs.getMailLogs();
            this.MailLogs = response.data;
            this.loading = false;
        }

    },
}
</script>
<style scoped>
.table > thead {
    vertical-align: bottom;
    background: lightskyblue;
}
tbody tr td {
    border-right: 1px solid #a7daf9;
}
tbody tr td:first-child {
    border-left: 1px solid #a7daf9;
}


tr.odd  { background-color:#FFF; vertical-align: middle;}
tr.even { background-color:#e5f5ff; vertical-align: middle;}
</style>